import * as api from '../api/index'

import { ADD_NEW_CONTENT, UPDATE_CONTENT, DELETE_CONTENT, FETCH_CONTENTS_BY_USER, FETCH_CONTENT, START_LOADING, END_LOADING,ADD_NEW_CERTIFICATE } from './constants'


export const getContent = (id) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchContent(id);
      dispatch({ type: FETCH_CONTENT, payload: { content: data } });

    } catch (error) {
      console.log(error);
    }
  };

  export const getContentsByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
    const  { data: { data } } = await api.fetchContentsByUser(searchQuery)
    //console.log(data);
      dispatch({ type: FETCH_CONTENTS_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const createContent =(client, openSnackbar) => async (dispatch) => {

    try {
        const { data } = await api.addContent(client)
        dispatch({ type: ADD_NEW_CONTENT, payload: data })
        openSnackbar("Content added successfully")

    } catch (error) {
        console.log(error)
    }
}

export const createCertificate =(client, openSnackbar) => async (dispatch) => {

  try {
      const { data } = await api.addCertificate(client)
      dispatch({ type: ADD_NEW_CERTIFICATE, payload: data })
      openSnackbar("Customer added successfully")

  } catch (error) {
      console.log(error)
  }
}



export const updateContent =(id, client, openSnackbar) => async (dispatch) => {

    const { data } = await api.updateClient(id, client)
    dispatch({ type: UPDATE_CONTENT, payload: data })
    openSnackbar("CMS updated successfully")
    try {
        
    } catch (error) {
        console.log(error)
    }
}

export const deleteContent =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteContent(id)

        dispatch({type: DELETE_CONTENT, payload: id})
        openSnackbar("CMS deleted successfully")
    } catch (error) {
        console.log(error)
    }
}