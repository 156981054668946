
import { ALL_CONTENTS, ADD_NEW_CONTENT, UPDATE_CONTENT, DELETE_CONTENT, FETCH_CONTENTS_BY_USER, FETCH_CONTENT, START_LOADING, END_LOADING,ADD_NEW_CERTIFICATE } from '../actions/constants'

const contents = (state = { isLoading: true, contents: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_CONTENTS:
        return {
          ...state,
          contents: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_CONTENTS_BY_USER:
        return { ...state, contents: action.payload };

      case FETCH_CONTENT:
        return { ...state, content: action.payload.content };
      case ADD_NEW_CONTENT:
        return { ...state, contents: [...state.contents, action.payload] };
        case ADD_NEW_CERTIFICATE:
        return { ...state, contents: [...state.contents, action.payload] };
      case UPDATE_CONTENT:
        return { ...state, contents: state.contents.map((content) => (content._id === action.payload._id ? action.payload : content)) };
      case DELETE_CONTENT:
        return { ...state, contents: state.contents.filter((content) => content._id !== action.payload) };
      default:
        return state;
    }
  };

  export default contents




//   const contents =(contents =[], action) => {
//     switch (action.type) {
//         case ALL_CONTENTS:
//             return action.payload

//         case FETCH_CONTENTS_BY_USER:
//             return action.payload

//         case ADD_NEW_CONTENT:
//             return [...contents, action.payload]

//         case UPDATE_CONTENT:
//             return contents.map((content) => content._id === action.payload ? action.payload : content)
        
//         case DELETE_CONTENT: 
//         return contents.filter((content) => content._id !== action.payload)
        
//         default:
//             return contents;
//     }
// }

// export default contents
