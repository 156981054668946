 /* eslint-disable */
import React, { useState, useEffect} from 'react'
import Contents from './Contents'
import Content from './Content'
import { getContentsByUser } from '../../actions/contentActions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import NoData from '../svgIcons/NoData'
import Spinner from '../Spinner/Spinner'
import FabButton from './Fab'


const ContentList = () => {
  //console.log("gaurav")

    const history = useHistory()
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const dispatch = useDispatch()
    const user = JSON.parse(localStorage.getItem('profile'))
    const {contents} = useSelector((state) => state.contents)
    //console.log(contents);
    const isLoading = useSelector(state => state.contents.isLoading)
    
    // const clients = []

    
    // useEffect(() => {
    // }, [currentId, dispatch]);
    
//     useEffect(() => {
//         dispatch(getClients(1));
//         // dispatch(getClientsByUser({userId : user?.result?._id}));
//         // dispatch(getClientsByUser({ search :user?.result?._id, tags: tags.join(',') }));
//     },[location]
// )

useEffect(() => {
    dispatch(getContentsByUser({ search: user?.result?._id || user.result.googleId }));
  },[location, dispatch])

  if(!user) {
    history.push('/login')
  }

  
  if(isLoading) {
    return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px'}}>
        <Spinner />
    </div>
  }

  if(contents.length === 0) {
    return  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '20px', margin: '80px'}}>
      <NoData />
      <FabButton />
    <p style={{padding: '40px', color: 'gray', textAlign: 'center'}}>No customers yet. Click the plus icon to add customer</p>
  
    </div>
  }

    return (
        <div>
            <FabButton />
            <Content 
                open={open} 
                setOpen={setOpen}
                currentId={currentId}
                setCurrentId={setCurrentId}
            />
            <Contents 
                open={open} 
                setOpen={setOpen}
                currentId={currentId}
                setCurrentId={setCurrentId}
                contents={contents}
            />
        </div>
    )
}

export default ContentList;

