import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'react-simple-snackbar'
import styles from './content.module.css'
import { createContent } from '../../actions/contentActions'


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(3),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  
const Content = ({ setOpen, open, currentId, setCurrentId }) => {
    const location = useLocation()
    const [clientData, setClientData] = useState({ title: '', description: '', userId: []})
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
    const dispatch = useDispatch()
    const content = useSelector((state)=> currentId ? state.contents.contents.find((c) => c._id === currentId) : null)
    // eslint-disable-next-line 
    const [openSnackbar, closeSnackbar] = useSnackbar()


    useEffect(() => {
      if(content) {
        setClientData(content)
      }
    }, [content])

    useEffect(() => {
      setUser(JSON.parse(localStorage.getItem('profile')))
      // setClientData({...clientData, userId: user?.result?._id})
    },[location])


    useEffect(() => {
      var checkId = user?.result?._id
      if(checkId !== undefined) {
        setClientData({...clientData, userId: [checkId]})
      } else {
        setClientData({...clientData, userId: [user?.result?.googleId]})
      }
      
    },[location])


    const handleSubmitClient =(e)=> {
        e.preventDefault()
          dispatch(createContent(clientData, openSnackbar))
        
        clear()
        handleClose()
    }

  const clear =() => {
    setCurrentId(null) 
    setClientData({ title: '', description: '', userId: [] })
  }
    
  const handleClose = () => {
    setOpen(false);
  };

  const inputStyle = {
    display: "block",
    padding: "1.4rem 0.75rem",
    width: "100%",
    fontSize: "0.8rem",
    lineHeight: 1.25,
    color: "#55595c",
    backgroundColor: "#fff",
    backgroundImage: "none",
    backgroundClip: "padding-box",
    borderTop: "0",
    borderRight: "0",
    borderBottom: "1px solid #eee",
    borderLeft: "0",
    borderRadius: "3px",
    transition: "all 0.25s cubic-bezier(0.4, 0, 1, 1)"
}

    return (
        <div>
        <form >
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
              <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{paddingLeft: '20px', color: 'white'}}>
              {currentId? 'Edit Customer' : 'Add new Client'}
              </DialogTitle>
              <DialogContent dividers>
  
  
              <div className="customInputs">
              <input 
                placeholder="Title" 
                style={inputStyle} 
                name='title' 
                type='text'  
                onChange={(e) => setClientData({...clientData, title: e.target.value})}
                value={clientData.title} 
              />

              
              <input 
                placeholder="Description" 
                style={inputStyle} 
                name='description' 
                type='text' 
                onChange={(e) => setClientData({...clientData, description: e.target.value})}
                value={clientData.description} 
              />
          </div>

          </DialogContent>
            <DialogActions>
            <Button  onClick={handleSubmitClient}  variant="contained" style={{marginRight: '25px'}} >
                Submit
            </Button>
    
        </DialogActions>
      </Dialog>
        
        </form>
        </div>
    
    );
}
 
export default Content