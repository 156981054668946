
export const FETCH_ALL = "FETCH_ALL"
export const ADD_NEW = "ADD_NEW"
export const UPDATE = "UPDATE"
export const DELETE = "DELETE"
export const GET_INVOICE = "GET_INVOICE"
export const FETCH_INVOICE_BY_USER = "FETCH_INVOICE_BY_USER"


export const ALL_CLIENTS = "ALL_CLIENTS"
export const UPDATE_CLIENT = "UPDATE_CLIENT"
export const DELETE_CLIENT = "DELETE_CLIENT"
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT"

export const FETCH_CLIENTS_BY_USER = 'FETCH_PROFILE_USER';

export const ALL_CONTENTS = "ALL_CONTENTS"
export const UPDATE_CONTENT = "UPDATE_CONTENT"
export const DELETE_CONTENT = "DELETE_CONTENT"
export const ADD_NEW_CONTENT = "ADD_NEW_CONTENT"
export const FETCH_CONTENT = "FETCH_CONTENT"
export const ADD_NEW_CERTIFICATE = "ADD_NEW_CERTIFICATE"
export const FETCH_CONTENTS_BY_USER = 'FETCH_CONTENT_USER';

export const AUTH = "AUTH"
export const LOGOUT = "LOGOUT"
export const UPDATE_USER = "UPDATE_USER"
export const FETCH_USERS = "FETCH_USERS"
export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"
export const FETCH_CLIENT = "FETCH_CLIENT"

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const FETCH_PROFILES = 'FETCH_PROFILES';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_BY_USER = 'FETCH_PROFILE_USER';
export const FETCH_PROFILES_BY_USER = 'FETCH_PROFILES_BY_USER';