import React from 'react'
import styles from './Home.module.css'

const Home = () => {
   
   
    return (
        
        <div className={styles.pageContainer}>
            
            <section id="hero">
        <div class="container flex flex-col-reverse md:flex-row items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0">
            {/* <!--Hero Text--> */}
            <div class="flex flex-col mb-32 space-y-12 md:w-1/2">
                <h1 class="mx-w-md text-4xl font-bold text-center md:text-5xl md:text-left">
                    KKNJS Invoice
                </h1>
                <p class="mx-w-sm text-center text-darkGrayishBlue md:text-left">
                    Online invoicing made simple.
                </p>
                <div class="flex justify-center md:justify-start">
                    <a href="/login" class= "p-3 px-6 py-2 text-white bg-brightRed rounded-full basleine hover:bg-brightRedLight">Get Started</a>
                </div>
            </div>
            {/* <!--Hero Image--> */}
            <div class="md:w-1/2">
             <img src="./images/illustration-intro.svg" />  
            </div>
        </div>
    </section>
    {/* <!--Features Section--> */}
    <section id="features">
        {/* <!--Features List--> */}
        <div class="flex flex-col space-y-3 md:space-y-2 md:space-x-6 md:flex-row">
                {/* <!--Heading--> */}
                <div class="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                    {/* <!--Heading--> */}
                <div class="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                    <div class="flex items-center space-x-2">
                        <div class="px-4 py-2 text-white rounded-full bg-brightRed md:py-1">
                            01
                        </div>
                        <h3 class="text-base font-bold px-1 md:mb-4 md:hidden">
                            Track company wide progress
                        </h3>
                    </div>
                </div>
                <div>
                   <h3 class="hidden mb-4 text-lg font-bold md:block">Track company wide progress</h3>
                   <p class="text-darkGrayishBlue">See how your day-to-day tasks fit into the wider vision. Go from tracking progress at the milestone level all the way done to the smallest of details. Never lose sight of the bigger picture again.</p>
                </div>
            </div>
            {/* <!--Item 2--> */}
            <div class="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
                {/* <!--Heading--> */}
                <div class="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                    <div class="flex items-center space-x-2">
                        <div class="px-4 py-2 text-white rounded-full bg-brightRed md:py-1">
                            02
                        </div>
                        <h3 class="text-base font-bold px-1 md:mb-4 md:hidden">
                            Advanced built-in reports
                        </h3>
                    </div>
                </div>
                <div>
                   <h3 class="hidden mb-4 text-lg font-bold md:block">Advanced built-in reports</h3>
                   <p class="text-darkGrayishBlue">Set internal delivery estimates and track progress toward company goals. Our customisable dashboard helps you build out the reports you need to keep key stakeholders informed. </p>
                </div>
            </div>
            {/* <!--Item-3--> */}
            <div class="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
                {/* <!--Heading--> */}
                <div class="rounded-l-full bg-brightRedSupLight md:bg-transparent">
                    <div class="flex items-center space-x-2">
                        <div class="px-4 py-2 text-white rounded-full bg-brightRed">
                            03
                        </div>
                        <h3 class="text-base font-bold px-1 md:mb-4 md:hidden">
                            Everything you need in one place
                        </h3>
                    </div>
                </div>
                <div>
                   <h3 class="hidden mb-4 text-lg font-bold md:block"> Everything you need in one place</h3>
                   <p class="text-darkGrayishBlue">Stop jumping from one service to another to communicate, store files, track tasks and share documents.Manage offers an all-in-one team productivity solution.</p>
                </div>
            </div>
       </div>
    </section>
    {/* <!--Testimonials-section--> */}
    <section id="testimonials">
        
        <div class="max-w-6xl px-5 mx-auto mt-32 text-center">
            <h2 class="text-4xl font-bold text-center">What's Different About Manage ?</h2>
            {/* <!--Testimonials-Container--> */}
            <div class="flex flex-col mt-24 md:flex-row md:space-x-6 ">
                {/* <!--Testimonial 1--> */}
                <div class="  flex flex-col p-6 bg-varyLightGray items-center space-y-6 rounded-lg md:w-1/3 ">
                    <img src="./images/avatar-anisha.png" class="w-16 -mt-14" alt=""/>
                    <h5 class="text-lg font-bold">Anisha Li</h5>
                    <p class="text-sm text-darkGrayishBlue">“Manage has supercharged our team’s workflow. The ability to maintain visibility on larger milestones at all times keeps everyone motivated.”</p>
                </div>

                  
                  <div class=" hidden flex-col p-6 bg-varyLightGray items-center space-y-6 rounded-lg md:w-1/3 md:flex">
                    <img src="./images/avatar-ali.png" class="w-16 -mt-14" alt=""/>
                    <h5 class="text-lg font-bold">Ali Bravo</h5>
                    <p class="text-sm text-darkGrayishBlue"> “We have been able to cancel so many other subscriptions since using Manage. There is no more cross-channel confusion and everyone is much more focused.”</p>
                </div>

                  
                  <div class="hidden flex-col p-6 bg-varyLightGray items-center space-y-6 rounded-lg md:w-1/3 md:flex">
                    <img src="./images/avatar-richard.png" class="w-16 -mt-14" alt=""/>
                    <h5 class="text-lg font-bold">Richard Watts</h5>
                    <p class="text-sm text-darkGrayishBlue"> “Manage allows us to provide structure and process. It keeps us organized and focused. I can’t stop recommending them to everyone I talk to!”</p>
                </div>
            </div>
            
            <div class="my-16">
                <a href="" class= "p-3 px-6 py-2 text-white bg-brightRed rounded-full basleine hover:bg-brightRedLight">Get Started</a>
            </div>
        </div>
    </section>
    
    <section id="cta" class="bg-brightRed">
        <div class="container flex flex-col items-center justify-between px-6 py-24  mx-auto space-y-12 md:py-12 md:flex-row md:space-y-0">
            <h2 class="text-5xl font-bold leading-tight text-center text-white md:text-4xl md:max-w-xl md:text-left">Simplify how your team works today</h2>
            <a href="" class= "p-3 px-6 py-2 text-brightRed bg-white rounded-full basleine  shadow-2xl hover:bg-gray-950">Get Started</a>
        </div>
    </section>        
    </div>

    )
}

export default Home
